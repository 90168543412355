@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Light";
  src: url("./fonts/Lato-Light.ttf") format("truetype");
}

body,
html {
  scrollbar-width: none !important;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}

.ant-drawer {
  z-index: 3000;
}
.scrollable-tabs-container {
  font-family: "Roboto", sans-serif;
  max-width: 816px;
  margin-left: auto;
  margin-right: auto;
  /* padding-top:10px;
  padding-bottom:10px; */
  border-radius: 4px;
  overflow: visible;
  position: relative;
}

.scrollable-tabs-container svg {
  width: 24px;
  height: 24px;
  padding: 8px;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  pointer-events: auto;
}

.scrollable-tabs-container ul {
  display: flex;
  gap: 16px;
  padding: 4px 8px;
  margin: 0;
  list-style: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.scrollable-tabs-container ul li {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.scrollable-tabs-container ul.dragging a {
  pointer-events: none;
}

.scrollable-tabs-container ul.dragging {
  scroll-behavior: auto;
}

.scrollable-tabs-container ul::-webkit-scrollbar {
  display: none;
}

.scrollable-tabs-container a {
  text-decoration: none;
  padding: 0px 12px;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  color: #4b5563;
}

.scrollable-tabs-container li {
  z-index: 1900;
}

.scrollable-tabs-container li.active {
  z-index: 1900;
  background: #fff;
  color: #000;
  -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
}

.scrollable-tabs-container li.active a {
  color: #000;
}

.scrollable-tabs-container .right-arrow,
.scrollable-tabs-container .left-arrow {
  position: absolute;
  height: 32px;
  width: 100px;
  top: 0;
  display: none;
  align-items: center;
  padding: 0 10px;
  pointer-events: none;
  z-index: 2000;
}

.scrollable-tabs-container .right-arrow.active,
.scrollable-tabs-container .left-arrow.active {
  display: flex;
}

.scrollable-tabs-container .right-arrow {
  right: 0;
  background: linear-gradient(to left, #fff 25%, transparent);
  justify-content: flex-end;
}

.scrollable-tabs-container .left-arrow {
  background: linear-gradient(to right, #fff 25%, transparent);
}

.scrollable-tabs-container svg {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #4f46e5;
}

.tabs-list {
  color: white;
}
